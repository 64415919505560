import React from 'react'
import './Footer.css'

const Footer = () => {
    return (
        <div className='footer_container'>
            <p>Made with ❤️ By TOM3R</p>
        </div>
    )
}

export default Footer